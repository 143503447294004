import {config} from '@modules/config';
import type {GetStaticProps} from 'next';
import {getFooterLinksPerLang} from '@app/modules/ssg-data/unlogged-data';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {LandingSections} from '@tempo/landing';
import {TempoLandingPage} from '@app/components/layout/LandingPage';
import type {LandingProps} from '@app/types/next';
import {useTranslation} from '@app/hooks/use-translation';
import {DownloadHero, Content, Footer} from '@app/views/download';
import {usePlatform} from '@app/hooks/use-os-platform';

const DownloadPage: React.FC<LandingProps> = () => {
	const {t} = useTranslation({ns: ['legacy']});
	const {platform, platformVersion} = usePlatform();
	const pageTitle = t(
		"Téléchargez Deezer gratuitement | Découvrez l'app mobile et ordinateur",
	);
	const pageDescription = t(
		'Téléchargez Deezer sur Windows, Mac, iOS, Android et sur tous vos appareils. Écoutez plus de %s million de titres en ligne mais aussi hors connexion.',
		{
			sprintf: [config.get('total_tracks')],
			count: +config.get('total_tracks'),
		},
	);

	return (
		<TempoLandingPage title={pageTitle} description={pageDescription}>
			<LandingSections topGutter>
				<DownloadHero platform={platform} platformVersion={platformVersion} />
				<Content platform={platform} platformVersion={platformVersion} />
				<Footer />
			</LandingSections>
		</TempoLandingPage>
	);
};

export const getStaticProps: GetStaticProps<LandingProps> = async ({
	locale = '',
	locales = [],
}) => {
	const translations = await serverSideTranslations(locale, [
		'legacy',
		'common',
	]);

	return {
		props: {
			...translations,
			dehydratedState: {
				...(await getFooterLinksPerLang({locale, locales})),
			},
			tracking: {
				pagecategory: 'download',
				pagename: 'download',
				cdpViewId: 'download',
			},
		},
	};
};

export default DownloadPage;
