import type {UsePlatform} from '@app/hooks/use-os-platform';

export const config = {
	link: {
		mac: 'https://www.deezer.com/desktop/download?platform=darwin&architecture=x64',
		windows:
			'https://www.deezer.com/desktop/download?platform=win32&architecture=x86',
	},
};

export const redirectToDownloadLink = (
	platform: UsePlatform['platform'],
	platformVersion: UsePlatform['platformVersion'],
) => {
	if (platform) {
		if (platformVersion && platform === 'mac') {
			return `https://www.deezer.com/desktop/download?platform=darwin&architecture=x64&platformVersion=${platformVersion}`;
		}
		return config.link[platform];
	}

	return '#';
};
