import {useState, useEffect} from 'react';
import {osName, OsTypes, osVersion} from 'react-device-detect';

export type UsePlatform = {
	platform: 'mac' | 'windows' | null;
	platformVersion: string | null;
};

export const usePlatform = () => {
	const [platform, setPlatform] = useState<'mac' | 'windows' | null>(null);
	const [platformVersion, setPlatformVersion] = useState<string | null>(null);

	useEffect(() => {
		const isMac = osName === OsTypes.MAC_OS;
		const isWindows = osName === OsTypes.Windows;
		const detectedPlatform = isMac ? 'mac' : isWindows ? 'windows' : null;

		setPlatform(detectedPlatform);
		setPlatformVersion(detectedPlatform ? osVersion : null);
	}, []);

	return {platform, platformVersion} as UsePlatform;
};
