import {LandingHeading} from '@tempo/landing';
import {useTranslation} from '@app/hooks/use-translation';
import {LandingButtonLink} from '@app/components/landing-btn-link';
import {Image} from '@app/components/Image';
import {useEffect} from 'react';
import {LandingIllustrationSection} from '@app/components/layout/LandingIllustrationSection';
import type {UsePlatform} from '@app/hooks/use-os-platform';
import {redirectToDownloadLink} from '@app/utils/downloadDeezerLink';

export const DownloadHero: React.FC<UsePlatform> = ({
	platform,
	platformVersion,
}) => {
	const {t} = useTranslation({ns: ['legacy']});

	useEffect(() => {
		if (location.href.includes('direct_dl')) {
			location.href = redirectToDownloadLink(platform, platformVersion);
		}
	}, [platform, platformVersion]);

	return (
		<LandingIllustrationSection
			description={
				<>
					<LandingHeading as="h1">
						{t('desktopappbanner_text_getfulldeezerexperience_web')}
					</LandingHeading>

					{platform && (
						<LandingButtonLink
							href={redirectToDownloadLink(platform, platformVersion)}
							isMain
						>
							{t('generic_action_downloadnow_web')}
						</LandingButtonLink>
					)}
				</>
			}
			image={
				<Image
					h={{base: '200px', md: '500px'}}
					alt={t('metatag_text_musicbasedonyourmood_standalone-web', {
						ns: 'common',
					})}
					quality={90}
					aspectRatio="1440/840"
					src="e2adab65c62df5fce5033f9ba72ab267.png"
					priority
				/>
			}
		/>
	);
};
